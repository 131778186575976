import * as React from "react";
import AppleDownloadCard from "./apple-download-card";
import { DownloadCardsContent } from "./download-cards.emotion";
import GoogleDownloadCard from "./google-download-card";

interface DownloadCardProps {
  height?: number;
  hGap?: number;
  isCentered?: boolean;
}

export default function DownloadCards({
  height = 83,
  hGap = 36,
  isCentered,
}: DownloadCardProps) {
  if (height <= 0) {
    height = 1;
  }
  return (
    <DownloadCardsContent hGap={hGap} isCentered={isCentered}>
      <AppleDownloadCard height={height} />
      <GoogleDownloadCard height={height} />
    </DownloadCardsContent>
  );
}
