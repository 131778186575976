import { Box, Stack } from "@mui/material";
import * as React from "react";
import { StyledLink } from "../home-page/home-page.emotion";
import { PageContent } from "../page-content.emotion";

export default function AdsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContent>
      <Stack
        spacing={4}
        justifyContent="center"
        mt={10}
        mr={7}
        ml={7}
        flexDirection="column"
      >
        <Box textAlign="center">
          <StyledLink to="/app-ads.txt" target="_blank" download>
            app-ads.txt
          </StyledLink>
        </Box>
      </Stack>
    </PageContent>
  );
}
