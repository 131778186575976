import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-underline-offset: 4px;
  color: black;
  font-weight: bold;
`;

export const StyledExternalLink = styled.a`
  text-underline-offset: 4px;
  color: black;
  font-weight: bold;
`;

export const ItchLink = styled.a`
  padding-left: 26px;
`;

export const Subtitle = styled.i`
  color: #777777;
`;
