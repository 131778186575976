import { Stack } from "@mui/material";
import * as React from "react";
import DownloadCards from "../../download-cards/download-cards";
import { PageContent } from "../page-content.emotion";

export default function DownloadPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContent>
      <Stack
        spacing={10}
        justifyContent="center"
        mt={10}
        flexDirection="column"
      >
        <div>
          <h1>Download Olive</h1>
          <DownloadCards />
        </div>
      </Stack>
    </PageContent>
  );
}
