import { Box, Stack } from "@mui/material";
import * as React from "react";
import InfoCard from "../../info-card/info-card";
import { PageContent } from "../page-content.emotion";
import Dustin from "../../../assets/images/Dustin.png";
import OlivePC from "../../../assets/images/OlivePC.png";
import Album from "../../../assets/images/Album.png";
import Steam from "../../../assets/images/steamLogo.png";
import Itch from "../../../assets/images/itchio_badge.png";
import DownloadCards from "../../download-cards/download-cards";
import {
  ItchLink,
  StyledExternalLink,
  StyledLink,
  Subtitle,
} from "./home-page.emotion";
import AppleDownloadCard from "../../download-cards/apple-download-card";
import GoogleDownloadCard from "../../download-cards/google-download-card";

export default function HomePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContent>
      <Stack
        spacing={10}
        justifyContent="center"
        mt={10}
        mr={7}
        ml={7}
        flexDirection="column"
      >
        <InfoCard image={Dustin} title="Hi there, my name is Dustin">
          <p>
            I work as a Software Developer, and create games and music in my
            spare time.
          </p>
          <p>
            I'm currently working on my second game, more information will be
            announced in the second half of 2024.
          </p>
        </InfoCard>
        <InfoCard image={OlivePC} title="Olive">
          <p>
            This arcade runner style game is my first game. Visit{" "}
            <StyledLink to={"/olive"}>Olive</StyledLink> for more information,
            or download it here.
          </p>
          <p>
            <Subtitle>Windows:</Subtitle>
          </p>
          <Box mt={1} mb={1}>
            <a
              href="https://store.steampowered.com/app/2831870/Olive/"
              target="_blank"
            >
              <img src={Steam} width={120} />
            </a>
            <ItchLink href="https://dustinhollis.itch.io/olive" target="_blank">
              <img src={Itch} width={120} />
            </ItchLink>
          </Box>
          <p>
            <Subtitle>Mobile:</Subtitle>
          </p>
          <DownloadCards height={40} hGap={24} />
        </InfoCard>
        <InfoCard image={Album} title="Echoes from Another Room">
          <p>A collection of instrumental songs, written 2013-2015.</p>
          <p>
            Listen at{" "}
            <StyledExternalLink
              target="_blank"
              href="https://dustinhollis.bandcamp.com/releases"
            >
              dustinhollis.bandcamp.com
            </StyledExternalLink>
            .
          </p>
        </InfoCard>
      </Stack>
    </PageContent>
  );
}
