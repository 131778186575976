import * as React from "react";
import { FooterContainer, FooterIcons, StyledLinkIcon } from "./footer.emotion";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Footer() {
  return (
    <FooterContainer>
      <p>dustin.hollis.games@gmail.com</p>
      <FooterIcons>
        <StyledLinkIcon href="https://www.instagram.com/dustin.hollis/">
          <InstagramIcon />
        </StyledLinkIcon>
        <StyledLinkIcon href="https://twitter.com/theDustinHollis">
          <TwitterIcon />
        </StyledLinkIcon>
      </FooterIcons>
    </FooterContainer>
  );
}
