import styled from "@emotion/styled";

export const FooterContainer = styled.div`
  padding: 56px;
`;

export const FooterIcons = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledLinkIcon = styled.a`
  color: black;
`;
