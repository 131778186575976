import * as React from "react";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import {
  HeaderContainer,
  ListContainer,
  NavHeaderContainer,
  StyledListItem,
} from "./header.emotion";
import { Drawer, IconButton, List } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { NAVIGATION_BREAKPOINT, WidthContext } from "../../App";

export default function Header() {
  const [open, setOpen] = React.useState(false);
  const width = React.useContext(WidthContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HeaderContainer>
        <h1>Dustin Hollis</h1>
        {width < NAVIGATION_BREAKPOINT ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            size="large"
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        ) : (
          <div>
            <Button
              component={NavLink}
              to="/"
              color="inherit"
              sx={{
                "&.active": {
                  textDecoration: "underline",
                  textUnderlineOffset: "11px",
                  textDecorationThickness: "2px",
                },
              }}
            >
              Home
            </Button>
            <Button
              component={NavLink}
              to="/olive"
              color="inherit"
              sx={{
                "&.active": {
                  textDecoration: "underline",
                  textUnderlineOffset: "11px",
                  textDecorationThickness: "2px",
                },
              }}
            >
              Olive
            </Button>
          </div>
        )}
      </HeaderContainer>
      {width < NAVIGATION_BREAKPOINT && (
        <Drawer
          sx={{
            width: "100%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "100%",
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <NavHeaderContainer>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={handleDrawerClose}
              size="large"
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </NavHeaderContainer>
          <ListContainer>
            <List>
              {["home", "olive"].map((text) => (
                <StyledListItem key={text}>
                  <Button
                    component={NavLink}
                    onClick={handleDrawerClose}
                    to={text === "home" ? "/" : "/" + text}
                    color="inherit"
                    sx={{
                      borderRadius: "0px",
                      "&.active": {
                        borderRight: "solid",
                        borderRightWidth: "3px",
                      },
                    }}
                  >
                    {text}
                  </Button>
                </StyledListItem>
              ))}
            </List>
          </ListContainer>
        </Drawer>
      )}
    </>
  );
}
