import styled from "@emotion/styled";

interface InfoCardTextProps {
  textWidth?: number;
}

export const InfoCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
`;

export const InfoCardImage = styled.img`
  margin-top: 20px;
  max-width: 220px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;

export const InfoCardText = styled.div<InfoCardTextProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.textWidth ?? 700}px;
`;
