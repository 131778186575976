import styled from "@emotion/styled";
import { ListItem } from "@mui/material";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 56px;
`;

export const NavHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 56px;
  margin: 14px 0 0 0;
`;

export const ListContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 56px;
`;

export const StyledListItem = styled(ListItem)`
  justify-content: flex-end;
`;
