import styled from "@emotion/styled";

interface GoogleDownloadImageProps {
  leftMargin: number;
}

interface DownloadCardsContentProps {
  hGap: number;
  isCentered?: boolean;
}

export const DownloadCardsContent = styled.div<DownloadCardsContentProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px ${(props) => props.hGap}px;
  justify-content: ${(props) => (props.isCentered ? "center" : "undefined")};
`;

export const AppleDownloadImage = styled.img`
  height: ${(props) => props.height};
`;

export const GoogleDownloadImage = styled.img<GoogleDownloadImageProps>`
  height: ${(props) => props.height};
  margin: 0px ${(props) => props.leftMargin}px;
`;
