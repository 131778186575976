import * as React from "react";
import {
  InfoCardContainer,
  InfoCardImage,
  InfoCardText,
} from "./info-card.emotion";

interface InfoCardProps {
  image?: string;
  leftElement?: React.ReactNode;
  title: string;
  children?: React.ReactNode;
  textWidth?: number;
}

export default function InfoCard({
  image,
  leftElement,
  title,
  children,
  textWidth,
}: InfoCardProps) {
  return (
    <InfoCardContainer>
      {image ? <InfoCardImage src={image} /> : undefined}
      {leftElement}
      <InfoCardText textWidth={textWidth}>
        <h1>{title}</h1>
        {children}
      </InfoCardText>
    </InfoCardContainer>
  );
}
