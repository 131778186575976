import * as React from "react";
import { AppleDownloadImage } from "./download-cards.emotion";

interface AppleCardProps {
  height: number;
}

export default function AppleDownloadCard({ height }: AppleCardProps) {
  if (height <= 0) {
    height = 1;
  }
  return (
    <a href="https://apps.apple.com/us/app/olive/id1588600798?itsct=apps_box_badge&amp;itscg=30200">
      <AppleDownloadImage
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1637884800&h=ecd1fd48a8636f231ef5d5944e8347dd"
        alt="Download on the App Store"
        height={height}
      />
    </a>
  );
}
