import * as React from "react";
import { GoogleDownloadImage } from "./download-cards.emotion";

interface GoogleCardProps {
  height: number;
}

export default function GoogleDownloadCard({ height }: GoogleCardProps) {
  if (height <= 0) {
    height = 1;
  }
  return (
    <a href="https://play.google.com/store/apps/details?id=com.DustinHollis.Olive&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <GoogleDownloadImage
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        alt="Get it on Google Play"
        height={height + height * (40 / 83)}
        leftMargin={height * (-19 / 83)}
      />
    </a>
  );
}
