import * as React from "react";
import { PageContent } from "../page-content.emotion";
import { ErrorContent } from "./error-page.emotion";

export default function ErrorPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContent>
      <ErrorContent>Sorry, this page doesn't exist</ErrorContent>
    </PageContent>
  );
}
