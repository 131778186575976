import React from "react";
import HomePage from "./components/page-content/home-page/home-page";
import Header from "./components/header/header";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/page-content/error-page/error-page";
import DownloadPage from "./components/page-content/download-page/download-page";
import Footer from "./components/footer/footer";
import PrivacyPage from "./components/page-content/policy-page/policy-page";
import AdsPage from "./components/page-content/ads-page/ads-page";
import OlivePCPage from "./components/page-content/olive-page/olive-pc-page";

export const CONTENT_BREAKPOINT = 1103;
export const NAVIGATION_BREAKPOINT = 620;
export const WidthContext = React.createContext(window.innerWidth);

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);

  return (
    <WidthContext.Provider value={width}>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/olive" element={<OlivePCPage />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/olive-privacy-policy" element={<PrivacyPage />} />
        <Route path="/app-ads-page" element={<AdsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </WidthContext.Provider>
  );
}

export default App;
