import { Box } from "@mui/material";
import * as React from "react";
import OlivePC from "../../../assets/images/OlivePC.png";
import InfoCard from "../../info-card/info-card";
import { PageContent } from "../page-content.emotion";
import Steam from "../../../assets/images/steamLogo.png";
import Itch from "../../../assets/images/itchio_badge.png";
import DownloadCards from "../../download-cards/download-cards";
import { ItchLink, Subtitle } from "../home-page/home-page.emotion";

export default function OlivePCPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageContent>
        <Box mt={10} mr={7} ml={7}>
          <InfoCard image={OlivePC} title="Olive">
            <p>
              Play as Olive, a sprightly old lady, to collect gems and
              progressively fill the scene with colour in this arcade runner
              style game. Grow plants with the coins you collect, unlock new
              abilities, and collect outfits to customise your look.
            </p>
            <p>
              <Subtitle>Windows:</Subtitle>
            </p>
            <Box mt={1} mb={1}>
              <a
                href="https://store.steampowered.com/app/2831870/Olive/"
                target="_blank"
              >
                <img src={Steam} width={120} />
              </a>
              <ItchLink
                href="https://dustinhollis.itch.io/olive"
                target="_blank"
              >
                <img src={Itch} width={120} />
              </ItchLink>
            </Box>
            <p>
              <Subtitle>Mobile:</Subtitle>
            </p>
            <DownloadCards height={40} hGap={24} />
          </InfoCard>
        </Box>
      </PageContent>
    </>
  );
}
