import { Stack } from "@mui/material";
import * as React from "react";
import { PolicyPageContent } from "./policy-page.emotion";

export default function PrivacyPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PolicyPageContent>
      <Stack
        spacing={4}
        justifyContent="center"
        mt={10}
        mr={7}
        ml={7}
        flexDirection="column"
      >
        <div>
          <h1>Privacy Policy - "Olive"</h1>
          <p>
            This policy is written to inform you of any information regarding my
            policies with the collection, use and disclosure of personal
            information for my app "Olive". I respect the privacy of my users
            and I'm committed to protect the user's information. I believe that
            you have a right to know my practices regarding the information I
            may collect and use when you use my app.
          </p>
        </div>
        <div>
          <h2>Unity Ads & Analytics</h2>
          <p>
            The “Olive” app collects personal data through the third party
            services, Unity Ads and Unity Analytics, for the purposes of
            third-party advertising and analytics. Unity Ads allows user data to
            be utilized for advertising communication purposes. This does not
            mean that all personal data are used for this purpose. For more
            information, please check the Privacy Policy of Unity:{" "}
            <a href="https://unity3d.com/legal/privacy-policy">
              Unity Privacy Policy.
            </a>
          </p>
          <p>
            More information on how these Unity services use information from
            this app can be found on the following pages:
          </p>
          <ul>
            <li>
              <a href="http://documentation.cloud.unity3d.com/en/articles/4665319-apple-nutritional-info-unity-ads">
                Unity Ads
              </a>
            </li>
            <li>
              <a href="http://documentation.cloud.unity3d.com/en/articles/4694233-apple-nutritional-info-unity-analytics">
                Unity Analytics
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2>Frequency of Ads</h2>
          <p>
            Ads are only displayed when you click on one of the “Watch Ad to
            Skip” buttons. These buttons will appear when an ability's cooldown
            period has been reached. These are optional ads, and they will allow
            you to skip the cooldown period of that ability if the ad is watched
            in full. These ads are muted by default. Otherwise there are no ads
            in this application.
          </p>
        </div>
        <div>
          <h2>Leaderboards & Game Data</h2>
          <p>
            My app uses Azure PlayFab for the purposes of leaderboard
            functionality. On start-up, your device ID will be shared with Azure
            PlayFab.
          </p>
          <p>
            To use the leaderboard, you will be prompted to enter a username.
            However, you may play this game without ever using the leaderboard,
            or without ever entering a username.
          </p>
          <p>
            If you enter a username, this will be shared with Azure PlayFab.
          </p>
          <p>
            If a username has been set, each time you access the leaderboard
            (via the Game Over Screen or the Options Menu), your most current
            score during the session will be shared with Azure PlayFab.
          </p>
          <p>
            See Sections 3.3, 3.5 and 3.6 (Acceptable Use Policy and Privacy) of
            the{" "}
            <a href="https://playfab.com/terms/">
              Azure PlayFab Terms of Service
            </a>{" "}
            for information on how Microsoft uses this data.
          </p>
          <p>
            If you want to remove your username and high score from Azure
            PlayFab services, you may select the “DELETE ONLINE DATA” button via
            the Information tab (the rightmost tab of the Options Menu). This
            will permanently delete your username and high score from Azure
            PlayFab services.
          </p>
        </div>
        <div>
          <h2>Mailing List</h2>
          <p>
            My app has a link to a page where you can enter your First Name,
            Last Name and Email Address to subscribe to my electronic mailing
            list. If you decide to opt-in to my mailing list, you may receive
            emails regarding updates to the game, or news related to any other
            games that I'm working on. Each email you receive has a link to
            unsubscribe from this list. You may also contact me if you want to
            be unsubscribed from this list. I will not use or share your email
            address with anyone except to deliver these emails.
          </p>
        </div>
        <div>
          <p>
            If you choose to use my app, then you agree to the collection and
            use of information in relation with this policy. I will not use or
            share your information with anyone except as described in this
            Privacy Policy.
          </p>
          <p>
            If you have any questions about this Privacy Policy please contact
            me at dustin.hollis.games@gmail.com.
          </p>
        </div>
        <div>
          <p>Dustin Hollis</p>
          <p>March 2023</p>
        </div>
      </Stack>
    </PolicyPageContent>
  );
}
